import React, { useState } from 'react';
import { Layout, Tabs, Button, Modal, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ResumeUpload from './ResumeUpload';
import ResumeParsing from './ResumeParsing';
import AnalyzeCandidate from './AnalyzeCandidate';
import AdminDashboard from './Role/AdminDashboard';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

const Dashboard = () => {
  const [selectedRole, setSelectedRole] = useState(null);  // To store the selected role
  const [uploadedFile, setUploadedFile] = useState(null);  // To store the uploaded file
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tabKey, setTabKey] = useState('1');  // To manage the active tab

  const showLoginModal = () => {
    setIsLoginModalVisible(true);
  };

  const handleLoginOk = () => {
    setIsLoginModalVisible(false);
    setIsLoggedIn(true);
    message.success('Logged in successfully');
  };

  const handleLoginCancel = () => {
    setIsLoginModalVisible(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    message.success('Logged out successfully');
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    handleLoginOk();
  };

  const handleTabChange = (key) => {
    setTabKey(key);  // Change the active tab
  };

  const handleFileUpload = (file) => {
    setUploadedFile(file);  // Store the uploaded file
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);  // Store the selected role
    setTabKey('2');  // Switch to the "Apply for a Role" tab when a role is selected
  };

  return (
    <Layout style={{ minHeight: '100vh', width: '100%', margin: 0 }}>
      <Header style={{ background: '#fff', height: '85px', padding: 0 }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          padding: '0 20px',
          height: '100%'
        }}>
          <a href="https://www.bschool.cuhk.edu.hk/centres/centre-for-family-business/" target="_blank" rel="noopener noreferrer">
            <img src="/images/cuhklogo.png" alt="CUHK Logo" style={{ marginTop: '28px', height: '85px' }} />
          </a>
          {isLoggedIn ? (
            <Button 
              type="primary" 
              onClick={handleLogout}
              style={{
                backgroundColor: '#4B2E83',
                borderColor: '#4B2E83'
              }}
            >
              Log out
            </Button>
          ) : (
            <Button 
              type="primary" 
              onClick={showLoginModal}
              style={{
                backgroundColor: '#4B2E83',
                borderColor: '#4B2E83'
              }}
            >
              Log in
            </Button>
          )}
        </div>
      </Header>

      <Content style={{ margin: '20px', background: '#fff', padding: '20px' }}>
        {isLoggedIn ? (
          <AdminDashboard />  // Display AdminDashboard after login
        ) : (
          <Tabs 
            activeKey={tabKey} 
            onChange={handleTabChange}  // Change tab handler
            destroyInactiveTabPane
          >
            <TabPane tab="Family Office Goals" key="1">
              <ResumeUpload 
                selectedRole={selectedRole}  // Pass the selected role to ResumeUpload
                onFileUpload={handleFileUpload} 
              />
              {selectedRole && uploadedFile && (
                <ResumeParsing 
                  file={uploadedFile} 
                  role={selectedRole} 
                />
              )}
            </TabPane>
            <TabPane tab="Analyze Candidate" key="2">
              <AnalyzeCandidate />
            </TabPane>
          </Tabs>
        )}
      </Content>

      <Modal
        title="Login"
        visible={isLoginModalVisible}
        onCancel={handleLoginCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              className="login-form-button" 
              style={{ width: '100%', backgroundColor: '#4B2E83', borderColor: '#4B2E83' }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
