import React from 'react';
import { Typography, Image, Row, Col, Divider } from 'antd';

const { Title, Text, Paragraph } = Typography;

const RecommendationPageContent = ({ recommendation }) => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Image width={100} src="/images/cuhklogo.png" preview={false} alt="CUHK Logo" />
      <Title level={2} style={{ marginTop: '10px' }}>Skill Recommendation Letter</Title>
      <Divider />
      
      {/* Sender Info */}
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Text strong>Chinese University of Hong Kong</Text><br />
          <Text strong>Center for Family Business</Text><br />
          <Text>6/F Cheng Yu Tung Building</Text><br />
          <Text>12 Chak Cheung Street</Text><br />
          <Text>Shatin, N.T., Hong Kong</Text><br />
          <Text>bafac@cuhk.edu.hk</Text>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text>{new Date().toLocaleDateString()}</Text>
        </Col>
      </Row>

      <Divider />

      {/* Recommendation content */}
      <Paragraph style={{ textAlign: 'justify', marginTop: '20px', fontSize: '16px' }}>
        {recommendation ? (
          <Text>{recommendation}</Text>
        ) : (
          <Text>No recommendation generated yet.</Text>
        )}
      </Paragraph>

      <Divider />

      {/* Footer Signature */}
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Text strong>John Doe</Text><br />
          <Text>Chief Learning Officer</Text><br />
          <Text>CUHK</Text><br />
        </Col>
      </Row>
    </div>
  );
};

export default RecommendationPageContent;
