import React, { useState, useEffect } from 'react';
import { Upload, message, Typography, Button, Card, Row, Col, Spin, Tag, Progress, Modal, Layout } from 'antd';
import axios from 'axios';
import { 
  InboxOutlined, CloseOutlined, UserOutlined, DollarOutlined, TeamOutlined, CalendarOutlined,
  MessageOutlined, GlobalOutlined, HeartOutlined, TrophyOutlined, SafetyOutlined,
  FundOutlined, BankOutlined, RocketOutlined, HistoryOutlined, HomeOutlined,
  ApartmentOutlined, ReadOutlined, ClockCircleOutlined
} from '@ant-design/icons';
import ResumeParsing from './ResumeParsing';
import RecommendationPageContent from './RecommendationPageContent';  

const { Dragger } = Upload;
const { Title, Text } = Typography;
const { Content, Sider } = Layout;

const colorMap = {
  group1: '#20B2AA',  // Lifestyle Concierge, Accounting & Bookkeeping, etc.
  group2: '#00BFFF',  // Public Affairs, Philanthropy (Principals & Policies), etc.
  group3: '#4682B4',  // Investment Policy, Wealth Management, etc.
  group4: '#778899'   // The rest
};

const iconMap = {
  'Lifestyle Concierge': UserOutlined,
  'Accounting & Bookkeeping': DollarOutlined,
  'Social Gathering': TeamOutlined,
  'Family Event Management': CalendarOutlined,
  'Family Communication': MessageOutlined,
  'Public Affairs': GlobalOutlined,
  'Philanthropy (Principals & Policies)': HeartOutlined,
  'Philanthropy (Project & Monitoring)': HeartOutlined,
  'Family Branding & Reputation Management': TrophyOutlined,
  'Crisis Management': SafetyOutlined,
  'Investment Policy': FundOutlined,
  'Wealth Management': BankOutlined,
  'Startup Fund': RocketOutlined,
  'Estate & Family Trust': BankOutlined,
  'Alternative Investment': FundOutlined,
  'Family History': HistoryOutlined,
  'Family Values and Heritage': HomeOutlined,
  'Family Structure & Organisation': ApartmentOutlined,
  'Family Education': ReadOutlined,
  'Succession Planning': ClockCircleOutlined
};

const roleCardColor = '#4682B4'; 

// Function to get the correct background color based on the goal name
const getBackgroundColor = (goalName) => {
  // Group 1: Lifestyle Concierge, Accounting & Bookkeeping, Social Gathering, etc.
  if (['Lifestyle Concierge', 'Accounting & Bookkeeping', 'Social Gathering', 'Family Event Management', 'Family Communication'].includes(goalName)) {
    return colorMap.group1;
  }
  
  // Group 2: Public Affairs, Philanthropy (Principals & Policies), etc.
  if (['Public Affairs', 'Philanthropy (Principals & Policies)', 'Philanthropy (Project & Monitoring)', 'Family Branding & Reputation Management', 'Crisis Management'].includes(goalName)) {
    return colorMap.group2;
  }
  
  // Group 3: Investment Policy, Wealth Management, etc.
  if (['Investment Policy', 'Wealth Management', 'Startup Fund', 'Estate & Family Trust', 'Alternative Investment'].includes(goalName)) {
    return colorMap.group3;
  }

  // Group 4: The rest (default color)
  return colorMap.group4;
};


const ResumeUpload = () => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [resumeFilePath, setResumeFilePath] = useState('');
  const [step, setStep] = useState(1);
  const [roleSkills, setRoleSkills] = useState([]);
  
  const [skillComparison, setSkillComparison] = useState({ 
    matchingSkills: [], 
    similarSkills: [], 
    lackingSkills: [], 
    extraSkills: [], 
    relevantAISkills: [], 
    relevantAITraits: []
  });
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recommendation, setRecommendation] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siderVisible, setSiderVisible] = useState(false);
  const {
    matchingSkills,
    similarSkills,
    lackingSkills,
    extraSkills,
  } = skillComparison;

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await axios.get(`${API_URL}/goals`);
        console.log('Fetched goals:', response.data);
        setGoals(response.data);
      } catch (error) {
        message.error('Error fetching goals');
      }
    };

    fetchGoals();
  }, [API_URL]);

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
    setSelectedRole(null);
    setSiderVisible(true);
  };



  const handleRoleClick = async (role) => {
    setSelectedRole(role);
    setStep(2);
    console.log('Selected role:', role);

    try {
      // Fetch role details from the backend
      const response = await axios.get(`${API_URL}/roles/${role.id}`);
      const roleData = response.data.role;

      if (roleData && (roleData.description || roleData.knowledge || roleData.skill || roleData.attribute)) {
        // Fetch AI-analyzed summary for the role description and additional fields
        setLoadingAnalysis(true);
        const aiResponse = await axios.post(`${API_URL}/analyze-role`, {
          description: roleData.description,
          knowledge: roleData.knowledge,
          skill: roleData.skill,
          attribute: roleData.attribute,
        });

        console.log('Role analysis response:', aiResponse.data);
        const analyzedRoleSkills = aiResponse.data.skills || [];
        setRoleSkills(analyzedRoleSkills);
        setAiAnalysis(aiResponse.data);
        setLoadingAnalysis(false);
      } else {
        message.warning('The selected role has no details to analyze.');
        setRoleSkills([]);
      }
    } catch (error) {
      console.error('Error fetching role or AI-analyzed summary:', error);
      message.error('Failed to fetch role details or analyze the role.');
      setLoadingAnalysis(false);
    }
  };

  const closeSider = () => {
    setSiderVisible(false);
  };
  
  const handleFileChange = (info) => {
    const { status, originFileObj } = info.file;

    if (status === 'done' || status !== 'uploading') {
      setFile(originFileObj);
      message.success(`${info.file.name} uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} upload failed.`);
    }
  };

  const handleSubmit = async () => {
    if (!file || !selectedRole) {
      message.error('Please upload a file and select a role before proceeding.');
      return;
    }
  
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('roleId', selectedRole.id);
  
    try {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scrolling effect
          });
          const response = await axios.post(`${API_URL}/upload-resume`, formData);
      const { resumeEntities = {}, matchingSkills = [], lackingSkills = [], filePath } = response.data;
  
      setParsedData({
        resumeEntities,
        matchingSkills,
        lackingSkills,
      });
  
      setResumeFilePath(filePath);
      setStep(3);
      message.success('Resume successfully parsed and processed!');
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('File upload failed. Please try again.');
    }
  };
  

  const handleSkillComparison = async () => {
    if (!parsedData || !roleSkills.length) {
      message.error('No parsed data or analyzed role skills found. Parse the resume and analyze the role first.');
      return;
    }
  
    try {

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scrolling effect
          });
      // Perform the comparison between resume skills and the analyzed role skills
      const response = await axios.post(`${API_URL}/compare-skills`, { 
        resumeSkills: parsedData.resumeEntities.skills, // These are the analyzed resume skills
        analyzedRoleSkills: roleSkills, // These are the AI-analyzed role skills
      });
  
      // Handle the comparison result from backend
      const comparisonData = response.data;
  
      // Helper function to remove duplicates
      const removeDuplicates = (arr) => [...new Set(arr.map(skill => skill.toLowerCase().trim()))];
  
      // Filter and remove any non-string elements from arrays
      const ensureStringArray = (arr) => arr.filter(skill => typeof skill === 'string');
  
      // Filter out non-string values before processing
      const filteredMatchingSkills = removeDuplicates(ensureStringArray(comparisonData.matchingSkills));
      const filteredSimilarSkills = removeDuplicates(ensureStringArray(comparisonData.similarSkills.map(skill => skill.jobSkill))); // Assuming 'similarSkills' has 'jobSkill'
      const filteredLackingSkills = removeDuplicates(ensureStringArray(comparisonData.lackingSkills.filter(skill => skill.split(' ').length > 1))); // Only multi-word phrases
      const filteredExtraSkills = removeDuplicates(ensureStringArray(comparisonData.extraSkills));
      const filteredRelevantAISkills = removeDuplicates(ensureStringArray(comparisonData.relevantAISkills));
      const filteredRelevantAITraits = removeDuplicates(ensureStringArray(comparisonData.relevantAITraits));
  
      setSkillComparison({
        matchingSkills: filteredMatchingSkills,
        similarSkills: filteredSimilarSkills,
        lackingSkills: filteredLackingSkills, 
        extraSkills: filteredExtraSkills,
        relevantAISkills: filteredRelevantAISkills,
        relevantAITraits: filteredRelevantAITraits,
      });
  
      message.success('Skills comparison completed successfully!');
      setStep(4);
    } catch (error) {
      console.error('Error comparing skills:', error);
      message.error('Failed to compare skills.');
    }
  };

  const calculateMatchingPercentage = (matchingSkills, similarSkills, totalSkills) => {
    const matchingWeight = 1.0; 
    const similarWeight = 0.7;   
  
    // Calculate total weighted score
    const weightedMatchScore = (matchingSkills.length * matchingWeight) + (similarSkills.length * similarWeight);
  
    // Calculate percentage by dividing the weighted score by the total number of skills
    const matchPercentage = (weightedMatchScore / totalSkills) * 100;
  
    return matchPercentage.toFixed(2);  // Return 2 decimal places
  };
  
  
  const renderAiAnalysis = () => (
    <div>
      <Title level={4}>Job KSAs</Title>


  
      <Card title="Top 20 Keys">
        {aiAnalysis?.topSkills?.length > 0 ? (
          aiAnalysis.topSkills.map((item, index) => (
            <Tag key={index} color="red">{item}</Tag>
          ))
        ) : (
          <p>No top skills found</p>
        )}
      </Card>
  
      <Card title="Knowledges" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.personalities?.length > 0 ? (
          aiAnalysis.personalities.map((item, index) => (
            <Tag key={index} color="blue">{item}</Tag>
          ))
        ) : (
          <p>No personalities found</p>
        )}
      </Card>
      <Card title="Skills" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.skills?.length > 0 ? (
          aiAnalysis.skills.map((item, index) => (
            <Tag key={index} color="purple">{item}</Tag>
          ))
        ) : (
          <p>No skills found</p>
        )}
      </Card>
  
      <Card title="Abilities" style={{ marginBottom: '10px' }}>
        {aiAnalysis?.abilities?.length > 0 ? (
          aiAnalysis.abilities.map((item, index) => (
            <Tag key={index} color="green">{item}</Tag>
          ))
        ) : (
          <p>No abilities found</p>
        )}
      </Card>
  

    </div>
  );

  const handleGenerateRecommendation = async () => {
    if (!selectedRole || !selectedRole.id) {
      console.error("Role is missing or invalid");
      message.error('Please select a role before generating a recommendation.');
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/generate-recommendation`, {
        roleId: selectedRole.id, // Send the selectedRole.id to the backend
        matchingSkills,
        similarSkills,
        lackingSkills
      });
      setRecommendation(response.data.recommendation);
      setIsModalVisible(true); // Show modal once recommendation is generated
    } catch (error) {
      console.error("Error generating recommendation:", error);
      message.error('Error generating recommendation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  
  const renderContent = () => {
    switch(step) {
      case 1:
        return (
          <>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
             Family Office Goals
            </Title>
            <Row gutter={[16, 16]}>
              {goals.map((goal, index) => {
                const IconComponent = iconMap[goal.goal_name] || UserOutlined;
                return (
                  <Col xs={24} sm={12} md={8} lg={6} key={goal.id}>
                    <Card
                      hoverable
                      onClick={() => handleGoalClick(goal)}
                      style={{
                        backgroundColor: getBackgroundColor(goal.goal_name),  // Use the getBackgroundColor function
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '20px',
                        minHeight: '150px',
                        borderRadius: '10px',
                      }}
                      bodyStyle={{ padding: 0 }}
                    >
                      <IconComponent style={{ fontSize: '36px', color: 'white', marginBottom: '10px' }} />
                      <Title level={5} style={{ color: 'white', margin: 0 }}>{goal.goal_name}</Title>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        );
        case 2:
          return selectedRole && (
            <div>
              <Title level={3} style={{ marginBottom: '20px' }}>Upload Your Resume</Title>
              <Card title={`Selected Role: ${selectedRole.role}`} style={{ marginBottom: '20px' }}>
                <p>{selectedRole.description}</p>
                {loadingAnalysis ? (
                  <Spin tip="Analyzing role..." />
                ) : (
                  aiAnalysis && renderAiAnalysis()
                )}
              </Card>
              <Dragger
                name="file"
                multiple={false}
                accept=".pdf,.doc,.docx"
                customRequest={({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                onChange={handleFileChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag a resume file to upload (PDF Recommended)</p>
              </Dragger>
              <Button type="primary" onClick={handleSubmit} style={{ marginTop: '20px', width: '100%' }}>
                Submit Resume for Skill Analysis
              </Button>
          </div>
        );
      case 3:
        return selectedRole && parsedData && (
          <div>
            <Title level={3}>Resume Analysis</Title>
            <Card title={`Selected Role: ${selectedRole.role}`} style={{ marginBottom: '20px' }}>
              {loadingAnalysis ? (
                <Spin tip="Analyzing role..." />
              ) : (
                aiAnalysis && renderAiAnalysis()
              )}
            </Card>
            <div style={{ padding: '20px' }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Resume Preview" style={{ marginBottom: '20px' }}>
                    {resumeFilePath ? (
                      <iframe
                        src={`${API_URL}${resumeFilePath}`}
                        style={{ width: '100%', height: '500px' }}
                        title="Resume Preview"
                      ></iframe>
                    ) : (
                      <p>No file available</p>
                    )}
                  </Card>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Candidate Resume Analysis">
                    <ResumeParsing parsedData={parsedData} roleSkills={roleSkills} aiAnalysis={aiAnalysis} />
                  </Card>
                </Col>
              </Row>
            </div>
            <Button type="primary" onClick={handleSkillComparison} style={{ marginTop: '20px', width: '100%' }}>
              Analyze and Compare Skills
            </Button>
          </div>
        );
      case 4:
        return selectedRole && (
          <div>
            <Title level={3}>Skills Comparison Result : {selectedRole.role}</Title>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <Progress
                    percent={calculateMatchingPercentage(matchingSkills, similarSkills, roleSkills.length)}
                    status="active"
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                  <Text strong>
                    Overall Match: {Math.round(calculateMatchingPercentage(matchingSkills, similarSkills, roleSkills.length))}%
                  </Text>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Matching Skills" extra={<Tag color="green">{matchingSkills.length}</Tag>}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {matchingSkills.map((skill, index) => (
                      <Tag key={index} color="green">{skill}</Tag>
                    ))}
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Lacking Skills" extra={<Tag color="red">{lackingSkills.length}</Tag>}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {lackingSkills
                      .filter(skill => skill.split(' ').length > 1)
                      .map((skill, index) => (
                        <Tag key={index} color="red">{skill}</Tag>
                      ))}
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Similar Skills" extra={<Tag color="blue">{similarSkills.length}</Tag>}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {similarSkills.map((skill, index) => (
                      <Tag key={index} color="blue">{skill}</Tag>
                    ))}
                  </div>
                </Card>
              </Col>
            </Row>
            <Button type="primary" onClick={handleGenerateRecommendation}>
              View Our Recommendation
            </Button>
            {loading && <Spin tip="Generating recommendation..." />}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '20px' }}>
        {renderContent()}
      </Content>
      <Sider
        width={400}
        style={{
          background: '#fff',
          padding: '20px',
          overflowY: 'auto',
        }}
        collapsed={!siderVisible}
        collapsedWidth={0}
        trigger={null}
      >
        {selectedGoal && (
          <>
            <Button
              icon={<CloseOutlined />}
              onClick={closeSider}
              style={{ marginBottom: '20px' }}
            />
            <Title level={3}>{selectedGoal.goal_name}</Title>
            <Text>{selectedGoal.description}</Text>
            {selectedGoal.roles && selectedGoal.roles.length > 0 ? (
              selectedGoal.roles.map((role) => {
                const IconComponent = iconMap[role.role] || UserOutlined;
                return (
                  <Card
                    key={role.id}
                    hoverable
                    onClick={() => handleRoleClick(role)}
                    style={{
                      marginBottom: '10px',
                      backgroundColor: roleCardColor,
                      borderRadius: '10px',
                    }}
                    bodyStyle={{
                      padding: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconComponent style={{ fontSize: '24px', color: 'white', marginRight: '10px' }} />
                    <Title level={5} style={{ margin: 0, color: 'white' }}>{role.role}</Title>
                  </Card>
                );
              })
            ) : (
              <Text>No roles available for this goal</Text>
            )}
          </>
        )}
      </Sider>
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="ok" type="primary" onClick={handleModalClose}>
            OK
          </Button>,
        ]}
        width={800}
      >
        <RecommendationPageContent recommendation={recommendation} />
      </Modal>
    </Layout>
  );
};

export default ResumeUpload;